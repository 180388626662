import React, { useState } from "react"
import Layout from "../../../components/layouts"
import Banner from "../../../components/features/Banner"
import SEO from "../../../components/common/SEO"
import ReactWOW from "react-wow"
import FeatureCards from "../../../components/features/FeatureCards"

export const query = graphql`
  query ContractorOrientations {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`

export default ({ data }) => {
  const [toggler, setToggler] = useState(false)
  const lightboxProps = {
    toggler: toggler,
    setToggler: setToggler,
    sources: ["https://www.youtube.com/watch?v=xE2yXvWB038"],
  }

  const bannerProps = {
    title: "Contractor and Visitor Orientations",
    subtitle: `Your employees probably have a good handle on your policies, procedures, and protocols. They've probably also been made aware of any site-specific hazards and appropriate controls to mitigate those hazards. But what about your contractors? They're even less familiar with your surroundings. They need to know this stuff as much as anyone.`,
    featureName: "behaviourObservations",
    rightButtonText: "Why Certificate Tracking Software?",
    rightButtonSrc: "https://www.youtube.com/watch?v=xE2yXvWB038",
    mockupImg:
      "/features/contractor-orientations/feature-page-header-orientations.png",
  }

  const seo = {
    title: "Workhub | Free Contractor Orientation Software Onboarding Training",
    description:
      "Introduce & onboard newly inducted contractors to your jobsite by hosting a video in our Contractor Orientation Tool.",
    image: "/features/contractor-orientations/social-card.jpg",
    // pathname,
    // article
  }

  const featureIndex = [
    {
      title: "Site Orientation",
      para: "Build a site orientation that covers who to report to, procedures for reporting incidents and emergencies, site evacuation, muster points, prohibited areas, and any other site-specific protocols you may have.",
    },
    {
      title: "Hosting",
      para: "If you already have an orientation video, we will host them for free, or we can offer competitive pricing on content creation with our in-house team.",
    },
    {
      title: "Screening",
      para: "Create screening questions to determine whether visitors are allowed onsite. For example: Proper PPE, training and that they meet government health requirements (e.g. Covid-19).",
    },
    {
      title: "Track Completion",
      para: "A record of completed orientations is available in your admin dashboard and is searchable by subcontractor company.",
    },
    {
      title: "Worker ID",
      para: "Look up whether individual workers have completed orientations by checking their WorkerID.",
    },
    {
      title: "Visitor Logs",
      para: "Log visitors and deliveries to a site using a QR code for easy access. ",
    },
    {
      title: "In / Out Board",
      para: "Require workers to check in and out of a site and review a manifest of everyone currently on site. ",
    },
    {
      title: "History Lookups",
      para: "See who accessed a location to assist with incident investigations or to enable contact tracing. ",
    },
    
  ]

  console.log(featureIndex)

  return (
    <Layout {...lightboxProps}>
      <SEO {...seo} {...data.site} />
      <Banner {...bannerProps} {...lightboxProps} />

      <main className="feature-main">
        <section className="feature-section">
          <div className="container" style={{ maxWidth: "1200px" }}>
            <div className="row">
              {/* SECTION ONE */}
              <div className="col-lg-6 feature-text">
                <h2>What should be in a Site Orientation?</h2>
                <p style={{marginBottom: '0px'}}>
                  Apart from the aforementioned policies, procedures and
                  protocols. Your site orientation should at a minimum cover:
                </p>

                <ol className="checklist">
                  <li>
                    Who is in charge and who to go with issues and concerns (and
                    where they're located)
                  </li>
                  <li>Procedures for reporting incidents and emergencies</li>
                  <li>
                    Details on a site evacuation and where the muster point is
                  </li>
                  <li>What PPE is required while on site</li>
                  <li>Prohibited areas</li>
                  <li>Unusual hazards</li>
                  <li>Site-specific protocols</li>
                </ol>

                <p>
                  Your site orientation might only take a few minutes, and can
                  be done by a gatekeeper, but that's time consuming and not
                  terribly scalable. A video hosted online is easier and
                  available 24/7.
                </p>
              </div>

              <div className="col-lg-6 feature-img">
                <img
                  src="/features/contractor-orientations/orientations-cartoon.png"
                  className="feature-img-left"
                  alt="Admin Laptop"
                />
              </div>
            </div>
          </div>
        </section>

        {/* SECTION TWO */}

        <section className="feature-section">
          <div className="container" style={{ maxWidth: "1200px" }}>
            <div className="row switch-children">
              <div className="col-lg-6 feature-img">
                <img
                  src="/features/contractor-orientations/c-orientations-worker-1.png"
                  className="feature-img-right"
                  alt="Worker Tablet"
                />
              </div>

              <div className="col-lg-6 feature-text">
                <h2>Perhaps you have an orientation video already.</h2>
                <p>
                  Workhub will host it at no charge. If you don't have an
                  orientation already, our content team can help create one from
                  your policies and procedures, ensuring that all the key
                  elements of your safety program are covered. We feel our work
                  is top-notch and our rates are competitive, but please feel
                  free to source your orientation elsewhere. Our primary
                  objective is safe worksites, so any orientation will do.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* SECTION THREE */}

        <section className="feature-section">
          <div className="container" style={{ maxWidth: "1200px" }}>
            <div className="row">
              <div className="col-lg-6">
                <h2>
                  Any contractor or visitor can watch your orientation in
                  Workhub for free.
                </h2>
                <p>
                  The record of completed orientations compiles in your
                  administrator portal and is searchable by subcontractor
                  company. The easiest way to ensure an orientation is completed
                  is to ask your contract workers for their WorkerID (another
                  Workhub feature). A record of completed orientations will be
                  available on their WorkerID compliance abstract, which is
                  stored offline.
                </p>
              </div>

              <div className="col-lg-6 feature-img">
                <img
                  src="/features/contractor-orientations/c-orientations-worker-3.png"
                  className="feature-img-left"
                  alt="Worker Mobile"
                />
              </div>
            </div>
          </div>
        </section>

        {/* FEATURE OVERVIEW */}

        <section className="feature-overview-container">
          <h2>Overview</h2>

          <div className="feature-overview-grid">
            {featureIndex.map(item => (
              <FeatureCards title={item.title} para={item.para} />
            ))}
          </div>
        </section>


      </main>
    </Layout>
  )
}
